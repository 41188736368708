@media only screen and (min-width: 350px) {
    .tinyhomes-text {
        font-size: 0.9em;
    }
    .tinyhomes-title {
        font-size: 1.5em;
        color: gray;
    }
    .tinyhomes-img {
        width: 100%;
        border-radius: 6px;
        height: auto;
        margin: 1em 0;
    }
    .tinyhomes-maintitle {
        line-height: 98%;
        letter-spacing: -1px;
        padding: 0.5em;
    }
}
@media only screen and (min-width: 600px) {}
@media only screen and (min-width: 800px) {}
@media only screen and (min-width: 1000px) {}
@media only screen and (min-width: 1200px) {}