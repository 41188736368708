@media only screen and (min-width: 350px) {
    .home-main-title {
        text-align: center;
        font-family: 'Oswald', sans-serif;
        font-weight: 900;
        font-size: 5em;
        letter-spacing: -5px;
    }

    .home-main-img {
        width: 100vw;
    }
    .home-panel2 {
        font-family: 'Roboto', sans-serif;
        padding: 1em;
    }
    .home-panel2-qui {
        text-align: center;
        font-size: 1.8em;
        color: black;
        letter-spacing: -1px;
    }
    .home-panel2-ans {
        font-size: 3em;
        text-align: center;
        width: fit-content;
        display: block;
        margin: 0.5em auto;
        padding: 0.2em 1em;
        border-radius: 10px;
        color: white;
        line-height: 95%;
    }
    .home-panel2-subtitle {
        margin-top: 2em;
        text-align: center;
        padding: 0.5em;
    }
    .home-panel2-wrapper {
        position: relative;
        border-radius: 10px;
    }
    .home-panel2-text {
        padding: 1.2em;
        font-size: 1em;
    }
    .home-panel2-img {
        border-radius: 10px;
        width: 100%;
        height: auto;
        position: relative;
    }
    .home-icon {
        position: absolute;
        font-size: 4em;
    }
    
}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 800px) {
    
}

@media only screen and (min-width: 1000px) {}

@media only screen and (min-width: 1200px) {
    .home-main-img {
        width: 50vw;
    }
}