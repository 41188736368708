@media only screen and (min-width: 350px) {
    .footer {
        background-color: black;
        color: white;
        padding: 1em;   
    }
    .footer-title {
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        font-size: 1.4em;
    }
    .footer-text {
        font-family: 'Roboto', sans-serif;
        font-size: 0.8em;
        width: 80vw;
    }
    .socials {
        display: flex;
        justify-content: center;
        padding: 1em;
    }
    .socials > * {
        font-size: 2em;
        margin-left: 10px;
        margin-right: 10px;
    }
    .footer-links {
        text-align: center;
    }
    .footer-Link {
        color: white;
        font-family: 'Roboto', sans-serif;
        text-decoration: none;
        display: block;
        padding: 0.3em;
    }
    .copyright {
        font-family: 'Roboto', sans-serif;
        font-size: 0.8em;
        text-align: center;
        margin-top: 1em;
        color: gray;
    }
}
@media only screen and (min-width: 600px) {}
@media only screen and (min-width: 800px) {}
@media only screen and (min-width: 1000px) {}
@media only screen and (min-width: 1200px) {}