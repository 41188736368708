* {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
}

.font1 {
  font-family: 'Roboto', sans-serif;
}
.font2 {
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
}
.spacer {
  height: 1em;
}
.center {
  text-align: center;
}

/* LAYOUT */
.p-sm {
  padding: 0.5em;
}

.p-md {
  padding: 1em;
}

.p-lg {
  padding: 1.5em;
}

.m-sm {
  margin: 0.5em;
}

.m-md {
  margin: 1em;
}

.m-lg {
  margin: 1.5em;
}

.bottomRight {
  bottom: -10px;
  right: -10px;
}

.bottomLeft {
  bottom: -10px;
  left: -10px;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

/* COLORS */
.bg-black {
  background-color: black;
}

.bg-gray {
  background-color: #6D8EA0;
}

.white {
  color: white;
}

.red {
  color: red;
}

.blue {
  color: #016FB9;
}

.lightBlue {
  color: #22AED1;
}

.yellow {
  color: #EFD10D;
}

.bg-lightGreenBlue {
  background-color: #70CCC3;
}

.bg-lightRed {
  background-color: #EB3D63;
}

.bg-yellow {
  background-color: #EFD10D;
}

.bg-blue {
  background-color: #016FB9;
}

.bg-lightBlue {
  background-color: #49B6FF;
}

/* CONTROLS */
.btn-black {
  display: block;
  border: 0;
  padding: 1.2em;
  width: 80vw;
  color: white;
  background-color: black;
  margin-right: auto;
  margin-left: auto;
  font-weight: bold;
  border-radius: 5px;
  font-size: 0.8em;
}

/* ROTATE */
.rotate-left {
  transform: rotate(-10deg);
}

.rotate-right {
  transform: rotate(10deg);
}

@media only screen and (min-width: 400px) {}
@media only screen and (min-width: 600px) {}
@media only screen and (min-width: 800px) {
  .wrapper {
    width: 75vw;
    transform: translateX(25vw);
  }
  .split {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 1000px) {}
@media only screen and (min-width: 1200px) {
  .wrapper {
    width: 85vw;
    transform: translateX(15vw);
  }
  .split {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}