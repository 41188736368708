@media only screen and (min-width: 400px) {
    .gallery-img {
        width: 100%;
        height: auto;
    }
}
@media only screen and (min-width: 600px) {}
@media only screen and (min-width: 800px) {
    .gallery {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
@media only screen and (min-width: 1000px) {
    .gallery {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}
@media only screen and (min-width: 1200px) {

}