@media only screen and (min-width: 350px) {
    .contact-title {
        font-size: 2.5em;
        letter-spacing: -2px;
        font-weight: 900;
    }
    .form {
        display: block;
        position: relative;
        margin-bottom: 1em;
    }
    .form-text {
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 80%;
        display: block;
        padding: 0.7em;
        border: 0;
        border-bottom: 2px solid black;
        margin: 1em auto;
    }
    .form-img {
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
    }
    .contact-info {
        font-size: 1em;
        margin: 0.5em 0;
        align-items: center;
    }
    .contact-icon {
        margin-right: 1em;
    }
}
@media only screen and (min-width: 600px) {}
@media only screen and (min-width: 800px) {}
@media only screen and (min-width: 1000px) {}
@media only screen and (min-width: 1200px) {}