@media only screen and (min-width: 250px) {
    .banner {
        background-color: black;
        color: white;
        text-align: center;
        font-family: 'Roboto', sans-serif;
    }
    .banner-text {
        padding: 1em;
        font-size: 0.9em;
    }
}

@media only screen and (min-width: 600px) {

}
@media only screen and (min-width: 800px) {
    
}
@media only screen and (min-width: 1000px) {

}
@media only screen and (min-width: 1200px) {

}