@media only screen and (min-width: 350px) {
    .navbar {
        
    }

    .navbar-top {
        display: flex;
        justify-content: space-between;
        width: 100vw;
        padding: 0.5em;
    }

    .navbar-title {
        font-size: 1.5em;
        font-weight: bold;
    }

    .navbar-icon {
        font-size: 1.8em;
    }

    .navbody {
        height: 100%;
        width: 100vw;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: #111;
        overflow-x: hidden;
        transition: 0.5s;
        color: white;
    }

    .navbody-top {
        display: flex;
        justify-content: space-between;
        padding: 1.5em;
    }

    .navbody-title {
        font-size: 1.5em;
        font-weight: 800;
    }

    .navbody-icon {
        font-size: 1.8em;
    }
    .navbody-links {
        margin-top: 20vh;
        padding: 1em;
    }
    .Link {
        color: white;
        text-decoration: none;
        display: block;
        font-size: 1.8em;
    }
    .navbody-footer {
        font-size: 0.8em;
        color: #696969;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 800px) {
    .navbody {
        width: 25vw;
    }
    .navbar-top {
        display: none;
    }
    .navbody-icon {
        display: none;
    }
    .navbody-title {
        font-size: 1.1em;
    }
    .Link {
        font-size: 1.2em;
    }
    .navbody-footer {
        display: none;
    }
    .Link:hover {
        color: #696969;
    }
}

@media only screen and (min-width: 1000px) {
    .navbody {
        width: 18vw;
    }
    .navbar-top {
        display: none;
    }
    .navbody-icon {
        display: none;
    }
    .navbody-title {
        font-size: 1.1em;
    }
    .Link {
        font-size: 1.2em;
    }
    .navbody-footer {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .navbody {
        width: 15vw;
    }
    .navbar-top {
        display: none;
    }
    .navbody-icon {
        display: none;
    }
    .navbody-title {
        font-size: 1.1em;
    }
    .Link {
        font-size: 1.2em;
    }
    .navbody-footer {
        display: none;
    }
}